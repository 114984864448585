<template>
  <workbook-result />
</template>

<script>
import WorkbookResult from '@/components/workbook/WorkbookResult.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ProfileWorkbookResult',
  components: { WorkbookResult },
  created() {
    this.fetchUserWorkbook(this.$route.params.id)
  },
  methods: {
    ...mapActions('workbook', ['fetchUserWorkbook']),
  },
}
</script>

<style></style>
